import classNames from "classnames";
import React from "react";
import { Icon, IconList } from "../Icon";
import { InternalExternalLink } from "../InternalExternalLink";

import * as styles from "./SocialLinks.module.scss";

type SocialItemProps = {
  icon: IconList;
  text: string;
  to: string;
  name: string;
};

const SOCIAL_LINKS: SocialItemProps[] = [
  {
    icon: "twitter",
    name: "Twitter Link",
    text: "Twitter",
    to: "https://twitter.com/jonburgerman",
  },
  {
    icon: "facebook",
    name: "Facebook Link",
    text: "Facebook",
    to: "https://www.facebook.com/jonburgermanartist/",
  },
  {
    icon: "instagram",
    name: "Instagram Link",
    text: "Instagram",
    to: "https://www.instagram.com/jonburgerman/",
  },
  {
    icon: "youtube",
    name: "Youtube Link",
    text: "Youtube",
    to: "https://www.youtube.com/user/jonburgerman",
  },
];

interface SocialLinksProps {
  className?: string;
}

const SocialLinks: React.FC<SocialLinksProps> = ({ className }) => {
  return (
    <ul className={classNames(styles.links, className)}>
      {SOCIAL_LINKS.map((item) => (
        <li key={item.icon}>
          <InternalExternalLink aria-label={item.name} to={item.to}>
            <Icon name={item.icon} />
          </InternalExternalLink>
        </li>
      ))}
    </ul>
  );
};

export { SocialLinks };
