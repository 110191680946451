import React from "react";
import classNames from "classnames";

import * as styles from "./Button.module.scss";
import { InternalExternalLink } from "../InternalExternalLink";
import { Icon, IconList, IconProps } from "../Icon";

interface ButtonProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
  to?: string;
  disabled?: boolean;
  theme?: "standard";
  size?: "small" | "normal" | "large";
  icon?: IconList;
  iconProps?: Partial<IconProps>;
  forceButton?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  to,
  disabled,
  onClick,
  children,
  className,
  theme = "standard",
  size = "normal",
  icon,
  iconProps = {},
  forceButton,
  ...props
}) => {
  const outerCls = classNames(
    styles.btn,
    {
      [styles.disabled]: disabled,
      [styles.iconOnly]: !children && icon,
    },
    [styles[size]],
    [styles[theme]],
    className
  );
  const { className: iconCls, ...restIconProps } = iconProps;

  const inner = (
    <span className={styles.inner}>
      {icon && (
        <Icon
          className={classNames(styles.icon, iconCls)}
          name={icon}
          {...restIconProps}
        />
      )}
      {children}
    </span>
  );

  if (to && !disabled) {
    return (
      <InternalExternalLink to={to} className={outerCls} {...props}>
        {inner}
      </InternalExternalLink>
    );
  }

  if (onClick || forceButton) {
    return (
      <button
        className={outerCls}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {inner}
      </button>
    );
  }

  return (
    <span className={outerCls} {...props}>
      {inner}
    </span>
  );
};

export { Button };
