import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";

import * as styles from "./Collapsible.module.scss";

interface CollapsibleProps {
  open?: boolean;
  className?: string;
}

const transitionElement = (
  el: HTMLDivElement,
  shouldOpen: boolean,
  options = {}
) => {
  if (!el) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve) => {
    el.classList.toggle(styles.open, !shouldOpen);
    const { height: height1 } = el.getBoundingClientRect();
    el.classList.toggle(styles.open, shouldOpen);
    const { height: height2 } = el.getBoundingClientRect();

    const animateOptions: KeyframeAnimationOptions = {
      duration: 500,
      fill: "forwards",
      easing: "cubic-bezier(0.4, 0, 0.2, 1)",
      ...options,
    };

    const anim = el.animate(
      [
        {
          height: `${height1}px`,
        },
        {
          height: `${height2}px`,
        },
      ],
      animateOptions
    );

    anim.onfinish = () => {
      anim.cancel();
      resolve();
    };
  });
};

const Collapsible: React.FC<CollapsibleProps> = ({
  open = false,
  children,
  className,
}) => {
  const [internalOpened, setInternalOpened] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open !== internalOpened) {
      transitionElement(wrapperRef.current, open).then(() => {
        setInternalOpened(open);
      });
    }
  }, [internalOpened, open]);

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, className)}>
      {children}
    </div>
  );
};

export { Collapsible };
