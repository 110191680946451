import React from "react";
import classNames from "classnames";

import * as styles from "./Icon.module.scss";

import { ReactComponent as X } from "./icons/x.svg";
import { ReactComponent as Plus } from "./icons/plus.svg";
import { ReactComponent as Move } from "./icons/move.svg";
import { ReactComponent as ArrowRight } from "./icons/arrow-right.svg";
import { ReactComponent as CloseOutline } from "./icons/close-outline.svg";
import { ReactComponent as MenuOutline } from "./icons/menu-outline.svg";
import { ReactComponent as Facebook } from "./icons/logo-facebook.svg";
import { ReactComponent as Instagram } from "./icons/logo-instagram.svg";
import { ReactComponent as Twitter } from "./icons/logo-twitter.svg";
import { ReactComponent as Youtube } from "./icons/logo-youtube.svg";

const icons = {
  x: <X />,
  plus: <Plus />,
  move: <Move />,
  "close-outline": <CloseOutline />,
  "menu-outline": <MenuOutline />,
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  youtube: <Youtube />,
  "arrow-right": <ArrowRight />,
};

export type IconList = keyof typeof icons;

export interface IconProps {
  name: IconList;
  className?: string;
  spin?: boolean;
  stroke?: boolean;
}

const Icon: React.FC<IconProps> = ({ className, name, spin, stroke }) => {
  return (
    <span
      className={classNames(
        styles.icon,
        { [styles.spin]: spin, [styles.stroke]: stroke },
        className
      )}
    >
      {icons[name]}
    </span>
  );
};

export { Icon };
