// extracted by mini-css-extract-plugin
export var bottom = "Navigation-module--bottom--b680d";
export var bottomCategories = "Navigation-module--bottom-categories--23744";
export var bottomCategoriesBtn = "Navigation-module--bottom-categories-btn--d1c51";
export var bottomFooter = "Navigation-module--bottom-footer--90925";
export var bottomFooterInfo = "Navigation-module--bottom-footer-info--454be";
export var bottomFooterLinks = "Navigation-module--bottom-footer-links--b96fc";
export var bottomFooterNewsletter = "Navigation-module--bottom-footer-newsletter--5bfd7";
export var bottomNav = "Navigation-module--bottom-nav--8b868";
export var bottomNavLink = "Navigation-module--bottom-nav-link--11409";
export var highlight = "Navigation-module--highlight--ecb14";
export var navigation = "Navigation-module--navigation--e9c31";
export var navigationBody = "Navigation-module--navigation-body--93b96";
export var open = "Navigation-module--open--04c46";
export var top = "Navigation-module--top--37f73";
export var topNav = "Navigation-module--top-nav--73438";
export var topNavLink = "Navigation-module--top-nav-link--9726f";
export var topToggle = "Navigation-module--top-toggle--f5f35";