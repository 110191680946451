import { tickUpdate } from "./utils";

export type UnlistenFunction = () => void;

export const eventFactory = (
  node: HTMLElement | Window | (() => HTMLElement | Window),
  evtType: string,
  options?: any
) => {
  const callbacks = [];

  const onFire = tickUpdate((e) => {
    callbacks.forEach((cb) => cb(e));
  });

  return (cb): UnlistenFunction => {
    let targetNode = typeof node === "function" ? node() : node;

    if (callbacks.length === 0) {
      targetNode.addEventListener(evtType, onFire, options);
    }

    callbacks.push(cb);

    return () => {
      const idx = callbacks.indexOf(cb);
      callbacks.splice(idx, 1);

      if (callbacks.length === 0) {
        targetNode.removeEventListener(evtType, onFire);
      }
    };
  };
};

const addResizeListener = eventFactory(() => window, "resize", {
  passive: true,
});
const addOrientationListener = eventFactory(() => window, "orientationchange", {
  passive: true,
});

export const onWindowResize = (cb): UnlistenFunction => {
  const unlisten = [];
  unlisten.push(addResizeListener(cb));
  unlisten.push(addOrientationListener(cb));

  return () => {
    unlisten.forEach((cb) => cb());
  };
};

export const registerBootlegVH = (): UnlistenFunction => {
  const setVh = () =>
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );

  const cb = tickUpdate(() => {
    setVh();
  });

  setVh();

  return onWindowResize(cb);
};
