import sanityClient from "@sanity/client";
import urlBuilder from "@sanity/image-url";
import { UnlistenFunction } from "./types";

export const client = sanityClient({
  projectId: "vuu15i2d",
  dataset: "production",
  apiVersion: "2019-01-29", // use current UTC date - see "specifying API version"!
  useCdn: true, // `false` if you want to ensure fresh data
});

export const builder = urlBuilder(client);

export const lerp = (a, b, n) => {
  return (1 - n) * a + n * b;
};

export const tickUpdate = (cb: (e?: any) => void) => {
  let ticking = false;

  const update = (e?: any) => {
    cb(e);
    ticking = false;
  };

  const requestTick = (e?: any) => {
    if (!ticking) {
      requestAnimationFrame(() => update(e));
      ticking = true;
    }
  };

  return requestTick;
};

export const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(num, min), max);
};

export const mapRange = (
  value: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

export const formToObject = (form: HTMLFormElement) => {
  const fd = new FormData(form);
  return [...fd.entries()].reduce(
    (prev, curr) => ({
      ...prev,
      [curr[0]]: curr[1],
    }),
    {}
  );
};

export function listen<T>(
  el: HTMLElement | Document,
  evtType: any,
  listener: (this: HTMLElement, ev: any) => any,
  options?: boolean | AddEventListenerOptions | undefined
): UnlistenFunction {
  el.addEventListener(evtType, listener, options);

  return () => {
    el.removeEventListener(evtType, listener);
  };
}

export const matchParentClass = (
  el: HTMLElement,
  className: string,
  depth = 5
) => {
  const getEl = (elt: HTMLElement | null, tries = 0): false | HTMLElement => {
    if (!elt || tries > depth) {
      return false;
    }
    if (elt.classList.contains(className)) {
      return elt;
    }

    return getEl(elt.parentElement, tries + 1);
  };

  return getEl(el);
};

const KEYS = {
  ESCAPE: 27,
};

export const registerExits = (onEscape) => {
  const cb = (e) => {
    if ([KEYS.ESCAPE].includes(e.keyCode)) {
      onEscape();
    }
  };

  document.addEventListener("keyup", cb);
  return () => document.removeEventListener("keyup", cb);
};

export const clickOutside = (
  el: HTMLElement,
  onClickOutside: () => void,
  validator?: (el: HTMLElement, e: MouseEvent) => boolean
) => {
  const cb = (e) => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside();
      }
    } else if (e.target !== el && !el.contains(e.target)) {
      onClickOutside();
    }
  };

  document.addEventListener("click", cb);
  const unregisterExits = registerExits(onClickOutside);

  return () => {
    unregisterExits();
    document.removeEventListener("click", cb);
  };
};

export const randomBetween = (low: number, high: number) => {
  const diff = Math.abs(low - high);
  return low + Math.random() * diff;
};

export const randomArrayIndex = (arr: any[]) => {
  return Math.floor(Math.random() * arr.length);
};

export const randomArray = (arr: any[]) => {
  return arr[randomArrayIndex(arr)];
};
