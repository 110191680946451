import classNames from "classnames";
import React, { useCallback, useState } from "react";

import * as styles from "./MailingList.module.scss";

interface MailingListProps {
  className?: string;
  userName?: string;
}

const MailingList: React.FC<MailingListProps> = ({
  className,
  userName = "jonburgerman",
}) => {
  const onButtondownSubmit = useCallback(() => {
    window.open(`https://buttondown.email/${userName}`, "popupwindow");
    return true;
  }, [userName]);

  // return (
  //   <form
  //     action={`https://buttondown.email/api/emails/embed-subscribe/${userName}`}
  //     method="post"
  //     target="popupwindow"
  //     onSubmit={onButtondownSubmit}
  //     className={className}
  //   >
  //     <div className={styles.inputs}>
  //       <div className={styles.inputsEmail}>
  //         <input
  //           type="email"
  //           name="email"
  //           id="bd-email"
  //           placeholder="E-mail address..."
  //           required
  //         />
  //       </div>

  //       <button type="submit">Subscribe</button>
  //     </div>
  //   </form>
  // );

  return (
    <iframe
      className={className}
      src="https://jonburgerman.substack.com/embed"
      width="100%"
      height="150"
      frameBorder="0"
      scrolling="no"
    />
  );
};

export { MailingList };
