import React, { useEffect, useMemo, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import slugify from "slugify";

import { Icon, IconList } from "../Common/Icon";
import { useSiteContext } from "../SiteContext";

import * as styles from "./Navigation.module.scss";
import { InternalExternalLink } from "../Common/InternalExternalLink";
import classNames from "classnames";
import { MenuIcon } from "../MenuIcon";
import { Button } from "../Common/Button";
import { MailingList } from "../MailingList";
import { Collapsible } from "../Common/Collapsible";
import { clickOutside } from "../../utils/utils";
import { SocialLinks } from "../Common/SocialLinks";

type NavItemProps = {
  text: string;
  to: string;
  highlight?: boolean;
};

const TOP_NAV: NavItemProps[] = [
  {
    text: "Contact",
    to: "/contact",
  },
  {
    text: "Shop",
    to: "https://www.burgerplex.com/",
    // highlight: true,
  },
];

const BOTTOM_NAV: NavItemProps[] = [
  {
    text: "FAQ",
    to: "/faq",
  },
];

interface NavigationQueryData {
  categories: {
    edges: Array<{
      node: {
        title: string;
        description: string;
        color: string;
      };
    }>;
  };
  nav: {
    edges: Array<{
      node: {
        title: string;
        slug: {
          current: string;
        };
        top_nav: boolean;
      };
    }>;
  };
}

const Navigation = () => {
  const { menuOpen, toggleMenuState, setInMenu, isTablet } = useSiteContext();
  const navRef = useRef<HTMLDivElement>(null);

  const { categories, nav } = useStaticQuery<NavigationQueryData>(graphql`
    query NavigationQuery {
      categories: allSanityCategory(sort: { fields: order }) {
        edges {
          node {
            title
            description
            color
          }
        }
      }
      nav: allSanityPage(
        filter: { in_nav: { eq: true }, slug: { current: { ne: null } } }
      ) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            top_nav
          }
        }
      }
    }
  `);

  const { top: topItems, bottom: bottomItems } = useMemo<{
    top: NavItemProps[];
    bottom: NavItemProps[];
  }>(() => {
    const inTop = nav.edges.filter(({ node }) => node.top_nav);
    const inBottom = nav.edges.filter(({ node }) => !node.top_nav);

    return {
      top: [
        ...inTop.map(({ node }) => ({
          to: `/${node.slug.current}`,
          text: node.title,
        })),
        ...TOP_NAV,
      ],
      bottom: [
        ...inBottom.map(({ node }) => ({
          to: `/${node.slug}`,
          text: node.title,
        })),
        ...BOTTOM_NAV,
      ],
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      return clickOutside(navRef.current, () => {
        toggleMenuState(false);
      });
    }
  }, [menuOpen]);

  return (
    <aside
      ref={navRef}
      className={classNames(styles.navigation, { [styles.open]: menuOpen })}
      onMouseEnter={() => setInMenu(true)}
      onMouseLeave={() => setInMenu(false)}
    >
      <div className={styles.top}>
        <MenuIcon
          className={styles.topToggle}
          open={menuOpen}
          onClick={toggleMenuState}
        />

        <nav className={styles.topNav}>
          {topItems.map((item) => (
            <div key={item.to} className={styles.topNavItem}>
              <InternalExternalLink
                className={classNames(styles.topNavLink, {
                  [styles.highlight]: item.highlight,
                })}
                to={item.to}
              >
                {item.text}
              </InternalExternalLink>
            </div>
          ))}
        </nav>
      </div>

      <Collapsible className={styles.navigationBody} open={menuOpen}>
        <div className={styles.bottom}>
          <div className={styles.bottomCategories}>
            {categories.edges.map(({ node }) => {
              return (
                <Button
                  key={node.title}
                  to={`/category/${slugify(node.title, { lower: true })}`}
                  className={classNames(styles.bottomCategoriesBtn, [
                    node.color,
                  ])}
                  // size="large"
                >
                  {node.title}
                </Button>
              );
            })}
          </div>
          {/* <nav className={styles.bottomNav}>
            {bottomItems.map((item) => (
              <div key={item.to} className={styles.bottomNavItem}>
                <InternalExternalLink
                  className={classNames(styles.bottomNavLink, {
                    [styles.highlight]: item.highlight,
                  })}
                  to={item.to}
                >
                  {item.text}
                </InternalExternalLink>
              </div>
            ))}
          </nav> */}

          <footer className={styles.bottomFooter}>
            <MailingList
              className={styles.bottomFooterNewsletter}
              userName="JonBurgerman"
            />

            <span className={styles.bottomFooterInfo}>
              © Jon Burgerman {new Date().getFullYear()}
            </span>

            <SocialLinks className={styles.bottomFooterLinks} />
          </footer>
        </div>
      </Collapsible>
    </aside>
  );
};

export { Navigation };
