import { PageProps } from "gatsby";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "../../utils/hooks/mediaQuery";

interface SiteContextData {
  isTablet: boolean;
  introMode: boolean;
  menuOpen: boolean;
  dismissIntroMode: () => void;
  toggleMenuState: (target?: boolean | any) => void;
  returnToHome: boolean;
  inMenu: boolean;
  setInMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiteContext = createContext<SiteContextData>({
  isTablet: true,
  introMode: true,
  menuOpen: false,
  toggleMenuState: () => false,
  dismissIntroMode: () => false,
  returnToHome: false,
  inMenu: false,
  setInMenu: () => false,
});

const SiteContainer: React.FC<{ location: PageProps["location"] }> = ({
  children,
  location,
}) => {
  const [introMode, setIntroMode] = useState(true);
  const [returnToHome, setReturnToHome] = useState(false);
  const [inMenu, setInMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const isTablet = useMediaQuery("screen and (min-width: 768px)");
  const initialRender = useRef(true);

  useEffect(() => {
    if (location.pathname !== "/") {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIntroMode(false);
        });
      });
    }
  }, []);

  const dismissIntroMode = useCallback(() => {
    setIntroMode(false);
  }, []);

  const toggleMenuState = useCallback((target?: boolean) => {
    if (typeof target === "boolean") {
      setMenuOpen(target);
    } else {
      setMenuOpen((s) => !s);
    }
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      setMenuOpen(false);
      dismissIntroMode();
    }

    initialRender.current = false;
  }, [location.pathname, dismissIntroMode]);

  useEffect(() => {
    if (!location.state) {
      return;
    }
    setReturnToHome(
      (location.state as any).fromHome && location.pathname !== "/"
    );
  }, [location]);

  return (
    <SiteContext.Provider
      value={{
        isTablet,
        introMode,
        dismissIntroMode,
        menuOpen,
        toggleMenuState,
        returnToHome,
        inMenu,
        setInMenu,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useSiteContext = (): SiteContextData => useContext(SiteContext);

export { SiteContainer, useSiteContext };
